import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Template({ data }) {
  const post = data.markdownRemark
  const postdate = new Date(post.frontmatter.date)
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  let day = days[postdate.getDay() % 7]
  

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      
      <div id="blog_post_content">
        <div className="blog_title">
          <h1>{post.frontmatter.title}</h1>
        </div>
        <div className="blog_subhead">
          <p className="blog_tagline">{post.frontmatter.tagline}</p>
          <p className="blog_credit">
            {post.frontmatter.author} &sdot; {post.frontmatter.date}
          </p>
        </div>

        <div className="blog_body">
          <h3>Happy {day}!</h3>

          <span dangerouslySetInnerHTML={{ __html: post.html }}></span>
        </div>
        <hr />
        <h4>&mdash;{post.frontmatter.author}</h4>  
        <small className="small_link">
          [<Link to="/blog">Back to the Blog</Link>]
        </small>
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query blogPostByPath($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      excerpt(pruneLength: 100)
      html
      frontmatter {
        path
        title
        tagline
        author
        date(formatString: "M/D/YYYY")
        tags
      }
    }
  }
`
